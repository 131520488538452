<template>
  <v-col v-if="create">
    <v-btn depressed class="pointer-events-none">
      <span :class="statusTextClass">
        {{ "data.status" | t }} ({{ statusLabel }})
      </span>
      <v-icon :class="statusTextClass"> fa fa-server </v-icon>
    </v-btn>
  </v-col>
</template>

<script lang="babel" type="text/babel">
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  computed: {
    create() {
      return false
      if(this.loadFailure) return false
      if(this.dataAction.status === false) return false
      return true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>